export const config = {
  api_url: process.env.REACT_APP_API_BASE_URL,
  // api_url: "http://localhost:8000/api/v1/",

  auth_url: process.env.REACT_APP_API_AUTH_URL,
  graphql_url: process.env.REACT_APP_GRAPHQL_BASE_URL,
  graphql_web_socket_url: process.env.REACT_APP_GRAPHQL_WEB_SOCKET_BASE_URL,
  workspace_id: process.env.REACT_APP_WORKSPACE_ID,
  encryption_key: process.env.REACT_APP_JWT_SECRET,
  prod_env: process.env.REACT_APP_PROD_ENV,
  build_version: process.env.REACT_APP_BUILD_VERSION,
  resellerdomainurl: "kovaion.ai",
};
